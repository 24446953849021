import { FC } from 'react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { Center, Heading, VStack, Text } from '@chakra-ui/react';

import {
  useInvalidateUseAuthUserQuery,
  useLoggedInUser,
} from '../../useAuthUser';
import { Button } from '../../ui-components/Button';
import { useTranslation } from '../../i18n';

export type OnboardingSuccessStepProps = {};

export const OnboardingSuccessStep: FC<OnboardingSuccessStepProps> = (
  props,
) => {
  const { t } = useTranslation();

  const invalidateAuthUser = useInvalidateUseAuthUserQuery();

  const user = useLoggedInUser();

  return (
    <VStack alignItems="stretch" spacing={8} paddingTop={8} textAlign="center">
      <Center color="brand.500">
        <CheckCircleIcon boxSize="24" color="currentColor" />
      </Center>

      <Heading>
        {t(
          'onboardingPage.onboardingSuccessMsg',
          'Congratulations, {{userName}}!',
          {
            userName: user.info.firstName,
          },
        )}
      </Heading>
      <Text>
        {t(
          'onboardingPage.onboardingSuccessInfo',
          'You have completed the onboarding process, now you can start using @media.',
        )}
      </Text>

      <Button
        onClick={() => {
          invalidateAuthUser();
        }}
      >
        {t('continue', 'CONTINUE')}
      </Button>
    </VStack>
  );
};
