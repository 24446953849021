import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { useApi } from '../../network/ApiProvider';
import { ApiResponse, AxiosApiError } from '../../network/types';

import { ForgotPasswordFormValues } from './types';

export const useForgotPasswordMutation = (
  opts?: UseMutationOptions<
    ApiResponse,
    AxiosApiError,
    ForgotPasswordFormValues
  >,
) => {
  const api = useApi();
  return useMutation<ApiResponse, AxiosApiError, ForgotPasswordFormValues>(
    async ({ captchaCode, email }) => {
      const { data } = await api.post(
        `/api/users/password/token?captchaCode=${captchaCode}`,
        { email },
      );

      return data;
    },
    opts,
  );
};
