import { useLocation } from 'react-router-dom';
import { Center, VStack, Heading, Text, HStack } from '@chakra-ui/react';

import { useTranslation } from '../i18n';
import { SignOutButton } from '../ui-components/SignOutButton';
import { LinkButton } from '../ui-components/LinkButton';

export const NotFoundPage = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <Center minH="100vh">
      <VStack spacing={15}>
        <VStack>
          <Heading>{t('notFoundPage.message', `Page not found`)}</Heading>
          <Text>
            {t(
              'notFoundPage.explanation',
              `You visited an address which doesn't exist, path: {{pathname}}`,
              { pathname },
            )}
          </Text>
        </VStack>

        <HStack>
          <SignOutButton variant="ghost" />
          <LinkButton to="/">{t('notFoundPage.home', 'HOME')}</LinkButton>
        </HStack>
      </VStack>
    </Center>
  );
};
