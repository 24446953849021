import { useEffect } from 'react';
import { Flex, useToast } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { Button } from '../ui-components/Button';
import { useTranslation } from '../i18n';

import { useChangeCampaignStateMutation } from './campaignPage/useChangeCampaignStateMutation';

const AcceptCampaignPage = () => {
  const { campaignId } = useParams();
  const changeCampaignStateMutation = useChangeCampaignStateMutation();

  const { t } = useTranslation();

  const toast = useToast();

  useEffect(() => {
    if (changeCampaignStateMutation.isSuccess) {
      const state = changeCampaignStateMutation.variables?.state.toLowerCase();
      toast({
        title: t(
          `campaignPage.${state}Success`,
          `Your campaign has been ${state}!`,
        ),
        status: state === 'active' ? 'success' : 'error',
        isClosable: true,
      });
    }
  }, [
    changeCampaignStateMutation.isSuccess,
    changeCampaignStateMutation.variables?.state,
    t,
    toast,
  ]);

  return (
    <Flex
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Button
        size="lg"
        onClick={() => {
          changeCampaignStateMutation.mutate({
            campaignId: campaignId || '',
            state: 'ACTIVE',
          });
        }}
        mr={2}
        isLoading={changeCampaignStateMutation.isLoading}
        disabled={changeCampaignStateMutation.isSuccess}
      >
        {t('campaignPage.acceptCampaign', 'Accept')}
      </Button>
      <Button
        size="lg"
        ml={2}
        colorScheme="black"
        onClick={() => {
          changeCampaignStateMutation.mutate({
            campaignId: campaignId || '',
            state: 'REJECTED',
          });
        }}
        isLoading={changeCampaignStateMutation.isLoading}
        disabled={changeCampaignStateMutation.isSuccess}
      >
        {t('campaignPage.rejectCampaign', 'Reject')}
      </Button>
    </Flex>
  );
};

// eslint-disable-next-line import/no-default-export
export default AcceptCampaignPage;
