import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Center,
  IconButton,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useBoolean } from 'react-use';

import { useTranslation } from '../i18n';

import { PasswordStrengthIndicator } from './PasswordStrengthIndicator';

export type NewPasswordFieldProps = InputProps & {
  passwordKey?: string;
};

const passwordMinLength = 8;

export const NewPasswordField = (props: NewPasswordFieldProps) => {
  const { passwordKey = 'password', ...inputProps } = props;

  const [showPassword, toggleShowPassword] = useBoolean(false);

  const { t, i18n } = useTranslation();
  const {
    register,
    watch,
    formState: { errors },
    reset,
  } = useFormContext();

  i18n.on('languageChanged', () => {
    reset();
  });

  const password = watch(passwordKey);

  return (
    <FormControl isInvalid={Boolean(errors.password)}>
      <FormLabel htmlFor={passwordKey}>
        {t('formFields.newPasswordLabel', 'Password')}
      </FormLabel>

      <InputGroup>
        <Input
          id={passwordKey}
          type={showPassword ? 'text' : 'password'}
          borderRadius="full"
          autoComplete="new-password"
          placeholder={t('formFields.newPasswordPlaceholder', 'New password')}
          {...register(passwordKey, {
            required: {
              value: true,
              message: t(
                'validationMessages.required',
                'This field is required',
              ),
            },
            minLength: {
              value: passwordMinLength,
              message: t('validationMessages.minLength', {
                defaultValue: 'Minimum length should be {{minLength}}',
                minLength: passwordMinLength,
              }),
            },
            pattern: {
              value:
                /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:<;=?>@[\]^_{`|~}])/,
              message: t(
                'validationMessages.incorrectPasswordPattern',
                'Password should contain at least one special character, one digit, one lower-case letter and one uppercase letter',
              ),
            },
          })}
          {...inputProps}
        />
        <InputRightElement
          children={
            showPassword ? (
              <IconButton
                variant="unstyled"
                aria-label="Hide password"
                onClick={toggleShowPassword}
                icon={<ViewOffIcon />}
              />
            ) : (
              <IconButton
                aria-label="Show password"
                variant="unstyled"
                onClick={toggleShowPassword}
                icon={<ViewIcon />}
              />
            )
          }
        />
      </InputGroup>

      {errors.password && (
        <FormErrorMessage>{errors.password.message as string}</FormErrorMessage>
      )}

      <Center>
        <PasswordStrengthIndicator
          as={FormHelperText}
          password={password}
          width="90%"
        />
      </Center>
    </FormControl>
  );
};
