import { FC } from 'react';

import { EmailField, EmailFieldProps } from '../../form-components/EmailField';

export type ForgotPasswordEmailFieldProps = EmailFieldProps;

export const ForgotPasswordEmailField: FC<ForgotPasswordEmailFieldProps> = (
  props,
) => {
  return <EmailField {...props} />;
};
