import {
  FormControl,
  FormLabel,
  FormLabelProps,
  RadioGroup,
  RadioGroupProps,
} from '@chakra-ui/react';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';

export type HookFormRadioGroupProps<T extends FieldValues> = {
  name: ControllerProps<T>['name'];
  controllerProps?: Omit<ControllerProps<T>, 'render' | 'name'>;
  labelProps?: FormLabelProps;
} & RadioGroupProps;

export const HookFormRadioGroup = <T extends FieldValues = {}>(
  props: HookFormRadioGroupProps<T>,
) => {
  const { name, labelProps, controllerProps, ...radioGroupProps } = props;

  return (
    <Controller<T>
      name={name}
      render={(props) => {
        const { field } = props;
        const { value, onChange, onBlur } = field;

        return (
          <FormControl>
            <FormLabel htmlFor={name} {...labelProps} />

            <RadioGroup
              id={name}
              {...radioGroupProps}
              value={value as string}
              onChange={(value) =>
                onChange({
                  target: { value, name },
                })
              }
              onBlur={onBlur}
            />
          </FormControl>
        );
      }}
      {...controllerProps}
    />
  );
};
