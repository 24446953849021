import { FC } from 'react';
import { Button, Image, Link } from '@chakra-ui/react';

import { useTranslation } from '../i18n';
import { appConfig } from '../appConfig';

export type MyWorldSSOButtonProps = {};

export const LoginWithMyWorldButton: FC<MyWorldSSOButtonProps> = () => {
  const { t } = useTranslation();

  return (
    <Button
      as={Link}
      variant="outline"
      color="black"
      borderRadius="3xl"
      fontWeight="normal"
      paddingX={5}
      paddingY={2}
      _hover={{ background: 'gray.100', textDecoration: 'none' }}
      _active={{ background: 'gray.100' }}
      href={`${appConfig.eppBackendServiceBaseUrl}/internal/oauth/authorize`}
      display={'flex'}
    >
      {/* TODO: translations */}
      {t('login.logInWith')}
      &nbsp;
      <Image
        src={`${process.env.PUBLIC_URL}/myworld_logo.svg`}
        alt="myworld logo"
      />
    </Button>
  );
};
