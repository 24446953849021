import { Link as RL, LinkProps as RLP } from 'react-router-dom';
import { Link as L, LinkProps as LP, forwardRef } from '@chakra-ui/react';

export const Link = forwardRef<LP, typeof L>((props, ref) => {
  return (
    <L
      color="black"
      _hover={{ color: 'blue.700' }}
      _visited={{ color: 'blue.900', _hover: { color: 'blue.700' } }}
      textDecoration="underline"
      {...props}
      ref={ref}
    />
  );
});

type RouterLinkProps = RLP & LP;

export const RouterLink = forwardRef<RouterLinkProps, typeof RL>(
  (props, ref) => {
    return <Link as={RL} {...props} ref={ref} />;
  },
);
