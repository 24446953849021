import { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { Button, ButtonProps } from './Button';

type LinkButtonProps = ButtonProps & LinkProps;

export const LinkButton: FC<LinkButtonProps> = (props) => (
  <Button {...props} as={Link} />
);
