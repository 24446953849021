import { createIcon } from '@chakra-ui/react';

export const CreditsIcon = createIcon({
  displayName: 'CreditsIcon',
  viewBox: '0 5 29 10',
  defaultProps: {
    stroke: 'currentcolor',
    fill: 'none',
  },
  path: [
    <path
      d="M27.5 5.32288V7.65621H1.5V5.32288C1.5 3.11374 3.29086 1.32288 5.5 1.32288H23.5C25.7091 1.32288 27.5 3.11374 27.5 5.32288Z"
      strokeWidth="2"
    />,
    <path
      d="M7.5 20.3229H5.5C3.29086 20.3229 1.5 18.532 1.5 16.3229V7.65621H27.5V16.3229C27.5 18.532 25.7091 20.3229 23.5 20.3229H13"
      strokeWidth="2"
      strokeLinecap="round"
    />,
    <line
      x1="16.5"
      y1="14.3229"
      x2="21.78"
      y2="14.3229"
      strokeWidth="2"
      strokeLinecap="round"
    />,
  ],
});
