import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputProps,
} from '@chakra-ui/react';
import { FC, MutableRefObject } from 'react';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from '../../i18n';
import { firstNamePattern, trimmedRequired } from '../../validators';

import { UserRegistrationFormValues } from './types';

export type RegistrationFirstNameFieldProps = {
  inputRef: MutableRefObject<HTMLInputElement>;
} & InputProps;

export const RegistrationFirstNameField: FC<RegistrationFirstNameFieldProps> = (
  props,
) => {
  const { inputRef, ...inputProps } = props;
  const { t, i18n } = useTranslation();
  const {
    register,
    formState: { errors },
    reset,
  } = useFormContext<UserRegistrationFormValues>();
  i18n.on('languageChanged', () => {
    reset();
  });
  const nameInputProps = register('firstName', {
    pattern: firstNamePattern(),
    validate: {
      trimmedRequired,
    },
  });

  return (
    <FormControl isInvalid={Boolean(errors.firstName)}>
      <FormLabel htmlFor="firstName">
        {t('registration.name', 'Name')}
      </FormLabel>
      <Input
        {...inputProps}
        id="firstName"
        type="text"
        borderRadius="full"
        autoComplete="given-name"
        placeholder={t('registration.namePlaceholder', 'Mathilda')}
        {...nameInputProps}
        ref={(e) => {
          if (!e) {
            return;
          }

          nameInputProps.ref(e);

          inputRef.current = e!;
        }}
      />

      {errors.firstName ? (
        <FormErrorMessage>{errors.firstName.message}</FormErrorMessage>
      ) : (
        <FormHelperText pointerEvents="none">&nbsp;</FormHelperText>
      )}
    </FormControl>
  );
};
