import { Center, Heading, VStack, Text, Flex } from '@chakra-ui/react';
import { FC } from 'react';

import { useIsAuthenticated } from './AuthProvider';
import { useTranslation } from './i18n';
import { Button } from './ui-components/Button';
import { SignOutButton } from './ui-components/SignOutButton';
import { LanguageFlags } from './ui-components/LanguageFlags';

export type GeneralErrorPageProps = {
  traceId?: string;
};

export const GeneralErrorPage: FC<GeneralErrorPageProps> = (props) => {
  const { traceId } = props;

  const { t } = useTranslation();

  const isAuthenticated = useIsAuthenticated()();

  return (
    <Center height="100vh" width="100vw">
      <LanguageFlags />
      <VStack spacing={10}>
        <VStack spacing={5}>
          <Heading size="3xl">{':('}</Heading>

          <Text fontSize="lg">
            {t(
              'app.generalAppError',
              'Something went wrong when loading your data',
            ) + `\ntraceId: ${traceId}`}
          </Text>
        </VStack>

        <Flex direction="column" gap={3}>
          <Button onClick={() => window.location.reload()}>
            {t('app.refreshThePage', 'Refresh the page')}
          </Button>

          {isAuthenticated && <SignOutButton variant="ghost" />}
        </Flex>
      </VStack>
    </Center>
  );
};

export const IndexGeneralErrorPage = () => {
  const { t } = useTranslation();

  const isAuthenticated = useIsAuthenticated()();

  return (
    <Center height="100vh" width="100vw">
      <LanguageFlags />
      <VStack spacing={10}>
        <VStack spacing={5}>
          <Heading size="3xl">{':('}</Heading>

          <Text fontSize="lg">
            {t(
              'app.generalAppError',
              'Something went wrong when loading your data',
            )}
          </Text>
        </VStack>

        <Flex direction="column" gap={3}>
          <Button onClick={() => window.location.reload()}>
            {t('app.refreshThePage', 'Refresh the page')}
          </Button>

          {isAuthenticated && <SignOutButton variant="ghost" />}
        </Flex>
      </VStack>
    </Center>
  );
};
