import { FC } from 'react';
import { Image, ImageProps } from '@chakra-ui/react';

import logoSrc from '../assets/mediabox_logo.svg';

export type BrandLogoProps = ImageProps;

export const BrandLogo: FC<BrandLogoProps> = (props) => {
  return <Image {...props} src={logoSrc} alt="brand logo" />;
};
