import axios, { AxiosRequestConfig } from 'axios';

export const createApi = (
  baseURL: string,
  config?: Omit<AxiosRequestConfig, 'baseURL'>,
) => {
  return axios.create({
    ...config,
    baseURL,
  });
};
