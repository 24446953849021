import { Box } from '@chakra-ui/react';
import { FC, PropsWithChildren, useState } from 'react';

import { add, subtractBy } from '../../functionHelpers';

import { CampaignTargetInfoStep } from './CampaignTargetInfoStep';
import { UserRoleInfoStep } from './UserRoleInfoStep';
import { CompanyInfoStep } from './CompanyInfoStep';

export type OnboardingSetUpStepProps = PropsWithChildren<{
  onCompleted: () => void;
}>;

export const OnboardingSetUpStep: FC<OnboardingSetUpStepProps> = (props) => {
  const { onCompleted } = props;

  const [stepIndex, setStepIndex] = useState(0);

  const onSubmit = () => {
    onCompleted();
  };

  const goToPrevStep = () => setStepIndex(subtractBy(1));
  const goToNextStep = () => setStepIndex(add(1));

  return (
    <>
      <Box hidden={stepIndex !== 0}>
        <CompanyInfoStep onSubmit={goToNextStep} />
      </Box>

      <Box hidden={stepIndex !== 1}>
        <UserRoleInfoStep onSubmit={goToNextStep} onBack={goToPrevStep} />
      </Box>

      <Box hidden={stepIndex !== 2}>
        <CampaignTargetInfoStep onSubmit={onSubmit} onBack={goToPrevStep} />
      </Box>
    </>
  );
};
