import { FC } from 'react';
import { BiLogOut } from 'react-icons/bi';

import { useTranslation } from '../i18n';
import { useSignOut } from '../AuthProvider';

import { Button, ButtonProps } from './Button';

export type SignOutButtonProps = ButtonProps;

export const SignOutButton: FC<SignOutButtonProps> = (props) => {
  const { t } = useTranslation();

  const signOut = useSignOut();

  return (
    <Button
      onClick={signOut}
      variant="ghost"
      leftIcon={<BiLogOut size="25" />}
      colorScheme="gray"
      padding={0}
      {...props}
    >
      {t('logOut', 'LOG OUT')}
    </Button>
  );
};
