import { Stack } from '@chakra-ui/react';
import { FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMount } from 'react-use';

import { useTranslation } from '../i18n';
import { FillingLoader } from '../ui-components/FillingLoader';

import { useConfirmAccountTransferMutation } from './confirmAccountTransferPage/useConfirmAccountTransferMutation';

export type ConfirmAccountTransferPageProps = {};

export const ConfirmAccountTransferPage: FC<
  ConfirmAccountTransferPageProps
> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const transferId = searchParams.get('transferId');

  const confirmAccountTransferMutation = useConfirmAccountTransferMutation({
    onSuccess: () => {
      navigate('/credits?transferConfirmationStatus=CONFIRMED');
    },
    onError: (error) => {
      if (
        error.response?.data?.type === 'urn:problem-type:user:transfer-expired'
      ) {
        navigate('/credits?transferConfirmationStatus=EXPIRED');
      } else if (
        error.response?.data?.type ===
        'urn:problem-type:account:debit-to-negative-not-allowed'
      ) {
        navigate('/credits?transferConfirmationStatus=NOT_ENOUGH_CREDITS');
      } else if (
        error.response?.data?.type ===
        'urn:problem-type:user:transfers-operation-not-allowed'
      ) {
        navigate('/credits?transferConfirmationStatus=NOT_ALLOWED');
      } else {
        navigate(
          `/credits?transferConfirmationStatus=FAILED&traceId=${error.response?.data?.sentryTraceId}__${error.response?.data?.elkTraceId}}`,
        );
      }
    },
  });

  useMount(() => {
    if (transferId) {
      confirmAccountTransferMutation.mutate({ transferId });
    }
  });

  return (
    <Stack
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <FillingLoader
        message={t(
          'creditsPage.transfer.confirmation.inProgress',
          'Your transfer request is being confirmed, please wait…',
        )}
      />
    </Stack>
  );
};
