import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { useAccountClient } from '../../network/ApiProvider';
import { ApiResponse, AxiosApiError } from '../../network/types';

type UseConfirmAccountTransferMutationParams = {
  transferId: string;
};

export const useConfirmAccountTransferMutation = (
  opts?: UseMutationOptions<
    ApiResponse,
    AxiosApiError,
    UseConfirmAccountTransferMutationParams
  >,
) => {
  const api = useAccountClient();
  return useMutation<
    ApiResponse,
    AxiosApiError,
    UseConfirmAccountTransferMutationParams
  >(async ({ transferId }) => {
    const { data } = await api.patch(`/api/transfers/${transferId}`, {
      status: 'CONFIRMED',
    });

    return data;
  }, opts);
};
