import { Decimal } from 'decimal.js';
import invariant from 'tiny-invariant';

import { clamp as cl } from './functionHelpers';

export * from 'decimal.js';

export const roundToPlaces = (n: number, places: number) => {
  return new Decimal(n).toDecimalPlaces(places).toNumber();
};

export const calculatePercent = (
  val: number,
  total: number,
  decimalPlaces = 2,
) =>
  new Decimal(val)
    .mul(100)
    .dividedBy(total)
    .toDecimalPlaces(decimalPlaces)
    .toNumber();

// https://www.trysmudford.com/blog/linear-interpolation-functions/
// "What number is 50% between 50 and 100?":
// lerp(50, 100, 0.5) == 75
export const lerp = (bottom: number, top: number, a: number) =>
  bottom * (1 - a) + top * a;

export const clamp = (a: number, min = 0, max = 1) => cl(min, max, a);

// "How many percent is 75 between 50 and 100?":
// invlerp(50, 100, 75) == 0.5
export const invlerp = (bottom: number, top: number, a: number) => {
  invariant(top >= bottom, 'invlerp: top is not >= bottom');

  return clamp((a - bottom) / (top - bottom));
};

// Find a value relative to some range projected on another range
//       Range 1  Range 2      Value
// range(10, 100, 2000, 20000, 50) == 10000
export const interpolateToRange = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  a: number,
) => lerp(x2, y2, invlerp(x1, y1, a));

export const companyNameFieldMaxLength = 128;
export const companyVatFieldMaxLength = 32;
export const addressFieldMaxLength = 256;
export const apartmentFieldMaxLength = 64;
export const postcodeFieldMaxLength = 32;
export const cityFieldMaxLength = 128;
