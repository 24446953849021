import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { useApi } from '../../network/ApiProvider';
import { ApiResponse, AxiosApiError } from '../../network/types';

export type CompanySize = 'MICRO' | 'SMALL' | 'MEDIUM' | 'BIG';

export type CampaignTargetType =
  | 'OWN_BUSINESS'
  | 'EMPLOYER_COMPANY'
  | 'FREELANCER_CLIENTS'
  | 'AGENCY_CLIENTS'
  | 'OTHER';

export type RoleType =
  | 'MARKETING_MANAGER'
  | 'CEO'
  | 'ENTREPRENEUR'
  | 'DESIGNER'
  | 'OTHER';

type UseUserOnboardingMutationParams = {
  campaignsTarget: {
    type: CampaignTargetType;
    detailsForOther: string | null;
  };
  company: {
    size: CompanySize;
    role: {
      type: RoleType;
      detailsForOther: string | null;
    };
    name: string;
    vat: string;
    address: {
      country: string;
      city: string;
      address: string;
      apartmentNumber: string | null;
      postcode: string;
    };
  };
};

export const useUserOnboardingMutation = (
  opts?: UseMutationOptions<
    ApiResponse,
    AxiosApiError,
    UseUserOnboardingMutationParams
  >,
) => {
  const api = useApi();
  return useMutation<
    ApiResponse,
    AxiosApiError,
    UseUserOnboardingMutationParams
  >(async (payload) => {
    const { data } = await api.patch('/api/users/details', payload);

    return data;
  }, opts);
};
