import { MutableRefObject, useRef } from 'react';

const defValue = {};
export const useRefWithInit = <T = unknown>(
  fn: () => T,
): MutableRefObject<T> => {
  const ref = useRef(defValue as T);

  if (ref.current === defValue) {
    ref.current = fn();
  }

  return ref;
};
