import invariant from 'tiny-invariant';
import { useCallback } from 'react';

import { AppLanguage, defaultAppLanguage, useAppLanguage } from './i18n';
import { QueryResult as DictionaryQueryResult } from './hooks/useDictionaryQuery';
// should never be actually called
export const exhaustivenessCheck = (value: never) => {
  invariant(
    false,
    `Exhaustiveness check not satisfied, value ${value}, ${typeof value}`,
  );
};

export const useFormatDictionary = (
  dictionaryData: DictionaryQueryResult = {},
) => {
  const [appLanguage] = useAppLanguage();
  const currentLanguageKey =
    appLanguage.toUpperCase() as Uppercase<AppLanguage>;
  const defaultLanguageKey =
    defaultAppLanguage.toUpperCase() as Uppercase<AppLanguage>;

  const entries = useCallback(
    () =>
      dictionaryData[currentLanguageKey] ??
      dictionaryData[defaultLanguageKey] ??
      [],
    [currentLanguageKey, defaultLanguageKey, dictionaryData],
  );

  return entries();
};

export const useFormatDictionaries = (
  dictionariesData: DictionaryQueryResult[],
) => {
  const [appLanguage] = useAppLanguage();
  const currentLanguageKey =
    appLanguage.toUpperCase() as Uppercase<AppLanguage>;
  const defaultLanguageKey =
    defaultAppLanguage.toUpperCase() as Uppercase<AppLanguage>;

  const entries = useCallback(
    () =>
      dictionariesData.map(
        (dictionaryData) =>
          dictionaryData[currentLanguageKey] ??
          dictionaryData[defaultLanguageKey] ??
          [],
      ),
    [currentLanguageKey, defaultLanguageKey, dictionariesData],
  );

  return entries();
};
