import invariant from 'tiny-invariant';

type MediaboxEnv = 'local' | 'development' | 'preprod' | 'production';

const TO_BE_REPLACED_DONT_TOUCH_THIS_MEDIABOX_ENV =
  // don't touch this literal, exactly this is replaced in the final bundle depending on the env
  'production';

export const mediaboxEnv: MediaboxEnv =
  process.env.NODE_ENV !== 'production'
    ? 'local'
    : (invariant(
        '__mediabox_env__'.toUpperCase() !==
          TO_BE_REPLACED_DONT_TOUCH_THIS_MEDIABOX_ENV,
        'mediabox env was not runtime replaced with a meaningful value',
      ),
      TO_BE_REPLACED_DONT_TOUCH_THIS_MEDIABOX_ENV as MediaboxEnv);
