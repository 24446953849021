import { FC } from 'react';
import { Heading, VStack, Stack } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useTranslation } from '../i18n';
import { Button } from '../ui-components/Button';
import { Alert } from '../ui-components/Alert';
import { HeroPage } from '../ui-components/HeroPage';

import { ResetPasswordForm } from './resetPasswordPage/ResetPasswordForm';

export type ResetPasswordPageProps = {};

export const ResetPasswordPage: FC<ResetPasswordPageProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const resetToken = searchParams.get('resetToken');

  const renderHeader = () => (
    <VStack textAlign="center">
      <Heading>
        {t('forgotPasswordReset.heading', 'Reset your password')}
      </Heading>
    </VStack>
  );

  const renderMissingKeyInfo = () => {
    return (
      <Stack spacing={8}>
        <Alert status="error">
          {t('forgotPasswordReset.missingCredentials', 'Missing credentials')}
        </Alert>
        <Button
          onClick={() => {
            navigate('/');
          }}
        >
          {t('forgotPasswordReset.returnToHome', 'Return to Home')}
        </Button>
      </Stack>
    );
  };

  return (
    <HeroPage>
      <VStack spacing={3} height="full">
        {renderHeader()}
      </VStack>
      {Boolean(resetToken) ? (
        <ResetPasswordForm resetToken={resetToken!} />
      ) : (
        renderMissingKeyInfo()
      )}
    </HeroPage>
  );
};
