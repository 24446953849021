import { FC, PropsWithChildren } from 'react';
import { Box, Center, Flex, Stack, VStack } from '@chakra-ui/react';

import { HeroImage } from './HeroImage';
import { BrandLogo } from './BrandLogo';
import { RouterLink } from './Link';
import { LanguageFlags } from './LanguageFlags';
import { Footer } from './Footer';

export type HeroPageProps = PropsWithChildren<{}>;

export const HeroPage: FC<HeroPageProps> = (props) => {
  const { children } = props;

  return (
    <Flex height="100vh">
      <LanguageFlags />
      <HeroImage flex={[0, null, 1]} />

      <VStack flex={1} overflowY="auto">
        <Center flex={1} paddingY={3}>
          <RouterLink to="/">
            <BrandLogo />
          </RouterLink>
        </Center>

        <Stack spacing={5} maxWidth="500px" width={['90%', null, '60%']}>
          {children}
        </Stack>

        <Box flex={1} />

        <Footer navPrefix={'/public'} />
      </VStack>
    </Flex>
  );
};
