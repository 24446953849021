import { useCallback } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Locize from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import HttpBackend from 'i18next-http-backend';

import { TupleValues } from './types';
import { mediaboxEnv } from './mediaboxEnv';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initForExtraction = () => {
  return i18n
    .use(Locize)
    .use(initReactI18next) // passes i18n down to react-i18next

    .init({
      lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option

      interpolation: {
        escapeValue: false, // react already saves from xss
      },
      saveMissing: true,
      debug: true,
      fallbackLng: 'en',
      backend: {
        projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID as string,
        apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
        private: true,
        referenceLng: 'en',
      },
    });
};

export const APP_LANGUAGES = [
  'en',
  'it',
  'si',
  'de',
  'mk',
  'pt',
  'sq',
  'lt',
  'sr',
  'hr',
] as const;

export type AppLanguage = TupleValues<typeof APP_LANGUAGES>;

export const defaultAppLanguage: AppLanguage = 'en';

export const initI18NForApp = () => {
  return i18n
    .use(LanguageDetector)
    .use(ChainedBackend)
    .use(initReactI18next) // passes i18n down to react-i18next

    .init({
      supportedLngs: APP_LANGUAGES,
      fallbackLng: defaultAppLanguage,

      interpolation: {
        escapeValue: false, // react already saves from xss
      },
      debug: mediaboxEnv !== 'production',

      detection: {
        // order and from where user language should be detected
        order: ['querystring', 'localStorage', 'navigator'],

        // keys or params to lookup language from
        lookupQuerystring: 'lang',
        lookupLocalStorage: 'i18nextLng',
      },

      backend: {
        backends: [
          HttpBackend, // if a namespace can't be loaded via normal http-backend loadPath, then the inMemoryLocalBackend will try to return the correct resources
          resourcesToBackend((language, namespace, callback) => {
            import(`./locales/${language}/${namespace}.json`)
              .then(({ default: resources }) => {
                // with dynamic import, you have to use the "default" key of the module ( https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import#importing_defaults )
                callback(null, resources);
              })
              .catch((error) => {
                callback(error, null);
              });
          }),
        ],
      } as any,
    });
};

// initForExtraction();

export const useAppLanguage = () => {
  const appLanguage = i18n.language as AppLanguage;

  const setAppLanguage = useCallback((lng: AppLanguage) => {
    localStorage.setItem('isDefaultLanguage', 'false');
    return i18n.changeLanguage(lng);
  }, []);

  return [appLanguage, setAppLanguage] as const;
};

export * from 'react-i18next';

export { i18n };
