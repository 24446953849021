import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { useApi } from '../../network/ApiProvider';
import { ApiResponse, AxiosApiError } from '../../network/types';

type ChangeCampaignStateValues =
  | 'ACTIVE'
  | 'INACTIVE'
  | 'REJECTED'
  | 'ACCEPTANCE_REQUIRED'
  | 'DRAFT'
  | 'DELETED';
type UseChangeCampaignStateMutationParams = {
  campaignId: string;
  state: ChangeCampaignStateValues;
};

export const useChangeCampaignStateMutation = (
  opts?: UseMutationOptions<
    ApiResponse,
    AxiosApiError,
    UseChangeCampaignStateMutationParams
  >,
) => {
  const api = useApi();
  return useMutation<
    ApiResponse,
    AxiosApiError,
    UseChangeCampaignStateMutationParams
  >(async ({ campaignId, state }) => {
    const { data } = await api.patch(`/api/campaigns/${campaignId}/state`, {
      state,
    });

    return data;
  }, opts);
};
