import { Heading, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';

import { RouterLink } from '../ui-components/Link';
import { HeroPage } from '../ui-components/HeroPage';
import { Trans, useTranslation } from '../i18n';

import { RegistrationForm } from './registrationPage/RegistrationForm';

export type RegistrationPageProps = {};

export const RegistrationPage: FC<RegistrationPageProps> = () => {
  const { t } = useTranslation();

  const renderHeader = () => (
    <VStack textAlign="center">
      <Heading>{t('registration.heading', 'Create an account')}</Heading>
      <Text>
        <Trans i18nKey="registration.logInPrompt">
          Already a member? <RouterLink to="/login">Log In</RouterLink>
        </Trans>
      </Text>
    </VStack>
  );

  return (
    <HeroPage>
      {renderHeader()}

      <RegistrationForm />
    </HeroPage>
  );
};
