import { FC } from 'react';

import { EmailField, EmailFieldProps } from '../../form-components/EmailField';

export type RegistrationEmailFieldProps = EmailFieldProps;

export const RegistrationEmailField: FC<RegistrationEmailFieldProps> = (
  props,
) => {
  return <EmailField {...props} />;
};
