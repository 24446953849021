import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
  styles: {
    global: () => ({
      '.chakra-checkbox:hover .chakra-checkbox__control': {
        backgroundColor: 'brand.500',
        borderColor: 'brand.500',
      },
      '.chakra-radio:hover .chakra-radio__control': {
        backgroundColor: 'brand.500',
        borderColor: 'brand.500',
      },
    }),
  },
};

export const theme = extendTheme(
  config,
  withDefaultColorScheme({ colorScheme: 'brand' }),
  {
    colors: {
      brand: {
        50: '#FBEBEA',
        100: '#F3C6C4',
        200: '#EBA29E',
        300: '#E37E78',
        400: '#DB5952',
        500: '#D3352C',
        600: '#A92A23',
        700: '#7F201A',
        800: '#541512',
        900: '#2A0B09',
      },
      black: {
        50: '#f8f0f2',
        100: '#d9d9d9',
        200: '#a6a6a6',
        300: '#737373',
        400: '#404040',
        500: '#323232',
        600: '#323232',
        700: '#323232',
        800: '#323232',
        900: '#000000',
      },
      gray: {
        50: '#F7F8FA',
        //   100: '#EFF0F6',
        200: '#E6E9EE',
        //   300: '#D3D9E5',
        //   400: '#BDC2D5',
        //   500: '#9DA5BE',
        600: '#7C86AB',
        //   700: '#656E87',
        800: '#383E54',
        //   900: '#13192F',
      },
      green: {
        300: '#40B70E',
      },
      red: {
        600: '#C53030',
      },
    },
    components: {
      Button: {
        baseStyle: {
          fontWeight: 'bold',
        },
      },
      ChakraLink: {
        baseStyle: {
          _hover: {
            backgroundColor: 'brand.500',
          },
        },
      },
      Input: {
        defaultProps: {
          variant: 'filled',
          borderRadius: 'full',
        },
        variants: {
          filled: {
            field: {
              // TODO: remove separate overrides of this on components
              borderRadius: 'full',
            },
          },
        },
      },
    },
  },
);
