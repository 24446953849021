import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useSearchParam = (name: string) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const value = searchParams.getAll(name);

  const setValue = useCallback(
    (newValue: null | ((v: string[] | null) => string[] | null) | string[]) => {
      const result = new URLSearchParams(searchParams);

      if (newValue === null) {
        result.delete(name);
        setSearchParams(result);
        return;
      }

      if (typeof newValue !== 'function') {
        result.delete(name);
        newValue.forEach((v) => result.append(name, v));
        setSearchParams(result);
        return;
      }

      const values = newValue(value);

      if (!values || values.length === 0) {
        result.delete(name);
        setSearchParams(result);
        return;
      }

      result.delete(name);
      values.forEach((v) => result.append(name, v));

      setSearchParams(result);
    },
    [name, searchParams, setSearchParams, value],
  );

  return [value, setValue] as const;
};
