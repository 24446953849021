import { createIcon } from '@chakra-ui/react';

export const HomeIcon = createIcon({
  displayName: 'HomeIcon',
  viewBox: '0 3 29 21',
  defaultProps: {
    stroke: 'currentcolor',
    fill: 'currentcolor',
  },
  path: [
    <path d="M20.0205 25.3229C19.4683 25.3229 19.0205 25.7706 19.0205 26.3229C19.0205 26.8752 19.4683 27.3229 20.0205 27.3229V25.3229ZM13.9658 27.3229C14.518 27.3229 14.9658 26.8752 14.9658 26.3229C14.9658 25.7706 14.518 25.3229 13.9658 25.3229V27.3229ZM16.7498 2.08219L16.2156 2.92755L16.7498 2.08219ZM20.0205 27.3229H22.2449V25.3229H20.0205V27.3229ZM28.2436 21.4453L28.4422 11.7173L26.4426 11.6764L26.244 21.4045L28.2436 21.4453ZM0.5 11.5087V21.3229H2.5V11.5087H0.5ZM6.5 27.3229H13.9658V25.3229H6.5V27.3229ZM25.6487 6.52267L17.284 1.23684L16.2156 2.92755L24.5803 8.21338L25.6487 6.52267ZM10.6844 1.36147L3.10557 6.56121L4.23704 8.21038L11.8158 3.01064L10.6844 1.36147ZM0.5 21.3229C0.5 24.6366 3.18629 27.3229 6.5 27.3229V25.3229C4.29086 25.3229 2.5 23.532 2.5 21.3229H0.5ZM2.5 11.5087C2.5 10.1902 3.1498 8.95633 4.23704 8.21038L3.10557 6.56121C1.47469 7.68014 0.5 9.5309 0.5 11.5087H2.5ZM28.4422 11.7173C28.4851 9.61597 27.4254 7.64542 25.6487 6.52267L24.5803 8.21338C25.7647 8.96188 26.4712 10.2756 26.4426 11.6764L28.4422 11.7173ZM17.284 1.23684C15.2569 -0.0441615 12.6617 0.00484905 10.6844 1.36147L11.8158 3.01064C13.1341 2.10622 14.8642 2.07355 16.2156 2.92755L17.284 1.23684ZM22.2449 27.3229C25.5108 27.3229 28.177 24.7106 28.2436 21.4453L26.244 21.4045C26.1996 23.5814 24.4222 25.3229 22.2449 25.3229V27.3229Z" />,
  ],
});
