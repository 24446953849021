import { FC } from 'react';

import { NewPasswordField } from '../../form-components/NewPasswordField';

export type RegistrationPasswordFieldProps = {};

export const RegistrationPasswordField: FC<
  RegistrationPasswordFieldProps
> = () => {
  return <NewPasswordField />;
};
