import { Center, CenterProps, Spinner, VStack, Text } from '@chakra-ui/react';
import { FC } from 'react';

import { useTranslation } from '../i18n';

export type LoaderProps = CenterProps & {
  message?: string;
};

export const Loader: FC<LoaderProps> = (props) => {
  const { t } = useTranslation();
  const {
    message = t('defaultLoadingMessage', 'Loading, please wait…'),
    ...centerProps
  } = props;

  return (
    <Center {...centerProps}>
      <VStack spacing={5}>
        <Text
          color="gray.800"
          fontSize="xl"
          fontWeight="thin"
          textAlign="center"
        >
          {message}
        </Text>

        <Spinner
          thickness="7px"
          speed="0.45s"
          emptyColor="gray.200"
          color="brand.500"
          size="xl"
          label=""
        />
      </VStack>
    </Center>
  );
};
