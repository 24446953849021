import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { useApi } from '../../network/ApiProvider';
import {
  authFlowsBasicAuthToken,
  OAuthTokenResponse,
  OAuthTokenResponseSchema,
} from '../../AuthProvider';
import { AxiosApiError } from '../../network/types';

type UseGetAuthTokenMutationParams = { authCode: string };

export const useSSOGetAuthTokenMutation = (
  opts?: UseMutationOptions<
    OAuthTokenResponse,
    AxiosError,
    UseGetAuthTokenMutationParams
  >,
) => {
  const api = useApi();
  return useMutation<
    OAuthTokenResponse,
    AxiosApiError,
    UseGetAuthTokenMutationParams
  >(async ({ authCode }) => {
    const formData = new URLSearchParams();

    formData.append('grant_type', 'authorization_code');
    formData.append('code', authCode);

    const { data } = await api.post('/internal/oauth/token', formData, {
      headers: {
        Authorization: authFlowsBasicAuthToken,
      },
    });

    return OAuthTokenResponseSchema.parse(data);
  }, opts);
};
