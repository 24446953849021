import { FC } from 'react';
import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Image,
} from '@chakra-ui/react';

import { FlagIcon } from '../icons/FlagIcon';
import ItalyFlag from '../assets/italy-flag-icon.png';
import SloveniaFlag from '../assets/slovenia-flag-icon.png';
import EnglishFlag from '../assets/united-kingdom-flag-icon.png';
import GermanFlag from '../assets/german-flag-icon.png';
import PortugalFlag from '../assets/portugal-flag-icon.png';
import MacedonianFlag from '../assets/macedonian-flag-icon.png';
import KosovoFlag from '../assets/kosovo-flag-icon.png';
import LithuanianFlag from '../assets/lithuanian-flag-icon.png';
import SerbianFlag from '../assets/serbia-flag-icon.jpeg';
import CroatianFlag from '../assets/croatia-flag-icon.png';
import {
  useAppLanguage,
  AppLanguage,
  useTranslation,
  APP_LANGUAGES,
} from '../i18n';
import { mediaboxEnv } from '../mediaboxEnv';

export type LanguageFlagsProps = {};

type FlagObject = {
  flag: string;
  alt: string;
  languageName: string;
  hideOnProduction?: boolean;
};

export const LanguageFlags: FC<LanguageFlagsProps> = () => {
  const [appLanguage, setAppLanguage] = useAppLanguage();

  const { t } = useTranslation();

  const flags: Record<AppLanguage, FlagObject> = {
    it: {
      flag: ItalyFlag,
      alt: t('italyFlag.alt', 'italy flag'),
      languageName: t('languages.italian', 'Italiano'),
    },
    si: {
      flag: SloveniaFlag,
      alt: t('slovenianFlag.alt', 'slovenian flag'),
      languageName: t('languages.slovenian', 'Slovenščina'),
    },
    en: {
      flag: EnglishFlag,
      alt: t('englishFlag.alt', 'english flag'),
      languageName: t('languages.english', 'English'),
    },
    de: {
      flag: GermanFlag,
      alt: t('germanFlag.alt', 'german flag'),
      languageName: t('languages.german', 'Deutsch'),
    },
    pt: {
      flag: PortugalFlag,
      alt: t('portugalFlag.alt', 'portugal flag'),
      languageName: t('languages.portugal', 'Portugal'),
    },
    mk: {
      flag: MacedonianFlag,
      alt: t('macedonianFlag.alt', 'macedonian flag'),
      languageName: t('languages.macedonian', 'македонски'),
    },
    sq: {
      flag: KosovoFlag,
      alt: t('kosovoFlag.alt', 'kosovo flag'),
      languageName: t('languages.kosovo', 'Kosovo'),
    },
    lt: {
      flag: LithuanianFlag,
      alt: t('lithuanianFlag.alt', 'lithuanian flag'),
      languageName: t('languages.lithuanian', 'Lithuanian'),
    },
    sr: {
      flag: SerbianFlag,
      alt: t('serbianFlag.alt', 'serbian flag'),
      languageName: t('languages.serbian', 'Serbia'),
    },
    hr: {
      flag: CroatianFlag,
      alt: t('croatianFlag.alt', 'croatian flag'),
      languageName: t('languages.croatian', 'Croatia'),
    },
  };

  return (
    <Flex position="fixed" right="20px" top="20px">
      <Menu>
        <MenuButton
          width="59px"
          padding={1.5}
          backgroundColor="transparent"
          boxShadow="sm"
          borderRadius="md"
          border="1px solid"
          borderColor="gray.300"
        >
          <Image
            margin="auto"
            width="35px"
            height="20px"
            src={flags[appLanguage].flag}
          />
        </MenuButton>
        <Portal>
          <MenuList minWidth="50px" zIndex="999">
            {APP_LANGUAGES.map((flagLanguage: AppLanguage) => {
              if (appLanguage === flagLanguage) return null;

              const { flag, alt, hideOnProduction, languageName } =
                flags[flagLanguage];

              return (
                <MenuItem
                  key={`language-flag-item-${flagLanguage}`}
                  gap="5px"
                  onClick={() => setAppLanguage(flagLanguage)}
                  display={
                    mediaboxEnv === 'production' && hideOnProduction
                      ? 'none'
                      : 'flex'
                  }
                >
                  <FlagIcon src={flag} alt={alt} />
                  {languageName}
                </MenuItem>
              );
            })}
          </MenuList>
        </Portal>
      </Menu>
    </Flex>
  );
};
