import { FC } from 'react';

import { NewPasswordConfirmationField } from '../../form-components/NewPasswordConfirmationField';

export type ResetPasswordPasswordConfirmationFieldProps = {};

export const ResetPasswordPasswordConfirmationField: FC<
  ResetPasswordPasswordConfirmationFieldProps
> = (props) => {
  return <NewPasswordConfirmationField />;
};
