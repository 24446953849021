import { FC } from 'react';
import { Box, BoxProps, Image } from '@chakra-ui/react';

import heroImageSrc from '../assets/mediabox_hero_image.webp';

export type HeroImageProps = BoxProps;

export const HeroImage: FC<HeroImageProps> = (props) => {
  return (
    <Box {...props}>
      <Image
        src={heroImageSrc}
        alt="mediabox hero image"
        height="full"
        width="full"
        fit="cover"
        pointerEvents="none"
      />
    </Box>
  );
};
