import { not, pipe, subtract, __, invertObj as iO } from 'ramda';

import { InvertRecord } from './types';

export const noop = (..._: any[]) => {};

export const negateFn = <T = unknown>(fn: (value: T) => unknown) =>
  pipe(fn, not);

export const subtractBy = (from: number) => subtract(__, from);

export const id = <T>(a: T): T => a;

export * from 'ramda';

export const invertObj = <T extends { [key: string]: string }>(o: T) => {
  return iO(o) as InvertRecord<T>;
};
