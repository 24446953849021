import { Text, Heading, VStack, useToast, Box } from '@chakra-ui/react';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useTranslation, Trans } from '../i18n';
import { useApi } from '../network/ApiProvider';
import { ApiResponse, AxiosApiError } from '../network/types';
import { Button } from '../ui-components/Button';
import { HeroPage } from '../ui-components/HeroPage';

export type RegistrationVerifyEmailPageProps = {};

type ResendActivationTokenMutationArgs = {
  email: string;
};

export const useResendActivationTokenMutation = (
  opts?: UseMutationOptions<
    ApiResponse,
    AxiosApiError,
    ResendActivationTokenMutationArgs
  >,
) => {
  const api = useApi();

  const mutation = useMutation<
    ApiResponse,
    AxiosApiError,
    ResendActivationTokenMutationArgs
  >(async ({ email }: { email: string }) => {
    const { data } = await api.post('/api/users/activation-token', {
      email,
    });

    return data;
  }, opts);

  return mutation;
};

export const RegistrationVerifyEmailPage: FC<
  RegistrationVerifyEmailPageProps
> = (props) => {
  const { t } = useTranslation();

  const toast = useToast();

  const [searchParams] = useSearchParams('email');
  const email = decodeURIComponent(searchParams.toString().split('=')[1]);

  const resendActivationTokenMutation = useResendActivationTokenMutation({
    onSuccess: () => {
      toast({
        title: t(
          'registrationVerifyEmail.successToastMsg',
          'Activation email sent successfully, check your inbox',
        ),
        status: 'success',
        isClosable: true,
      });
    },

    onError: (error) => {
      if (
        error.response?.data?.type ===
        'urn:problem-type:external-activation-not-allowed'
      ) {
        toast({
          title:
            t(
              'registrationVerifyEmail.externalActivationNotAllowed',
              'External activation by token not allowed for INTERNAL user',
            ) +
            `\ntraceId: ${error.response?.data?.sentryTraceId}__${error.response?.data?.elkTraceId}`,
          status: 'error',
          isClosable: true,
        });
      } else {
        toast({
          title:
            t(
              'registrationVerifyEmail.errorToastMsg',
              'Activation email failed to resend, try again…',
            ) +
            `\ntraceId: ${error.response?.data?.sentryTraceId}__${error.response?.data?.elkTraceId}`,
          status: 'error',
          isClosable: true,
        });
      }
    },
  });

  return (
    <HeroPage>
      <VStack spacing={3} height="full">
        <VStack textAlign="center">
          <Heading>
            {t('registrationVerifyEmail.heading', 'Verify your email')}
          </Heading>
          <Text>
            <Trans i18nKey="registrationVerifyEmail.subheading">
              {'An account confirmation email has been sent to your inbox.'}
              <Box as="br" />
              <Box as="span" hidden={!email}>
                {'Didnʼt get an email? '}
                <Button
                  variant="link"
                  isLoading={resendActivationTokenMutation.isLoading}
                  onClick={() => {
                    if (email) {
                      resendActivationTokenMutation.mutate({ email });
                    }
                  }}
                >
                  {'Resend.'}
                </Button>
              </Box>
            </Trans>
          </Text>
        </VStack>
      </VStack>
    </HeroPage>
  );
};
