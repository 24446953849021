import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { useApi } from '../../network/ApiProvider';
import { AxiosApiError } from '../../network/types';
import {
  authFlowsBasicAuthToken,
  OAuthTokenResponse,
  OAuthTokenResponseSchema,
} from '../../AuthProvider';

import { UserLoginFormValues } from './types';

export const useLoginMutation = (
  opts?: UseMutationOptions<
    OAuthTokenResponse,
    AxiosApiError,
    UserLoginFormValues
  >,
) => {
  const api = useApi();
  return useMutation<OAuthTokenResponse, AxiosApiError, UserLoginFormValues>(
    async ({ email, password, captchaCode }) => {
      const formData = new URLSearchParams();

      formData.append('grant_type', 'password');
      formData.append('scope', 'read');
      formData.append('username', email);
      formData.append('password', password);
      formData.append('captchaCode', captchaCode);

      const { data } = await api.post('/oauth/token', formData, {
        autoLogout: false,
        headers: {
          Authorization: authFlowsBasicAuthToken,
        },
      });

      return OAuthTokenResponseSchema.parse(data);
    },
    opts,
  );
};
