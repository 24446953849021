import { FC } from 'react';

import {
  NewPasswordField,
  NewPasswordFieldProps,
} from '../../form-components/NewPasswordField';

export type ResetPasswordPasswordFieldProps = NewPasswordFieldProps;

export const ResetPasswordPasswordField: FC<ResetPasswordPasswordFieldProps> = (
  props,
) => {
  return <NewPasswordField {...props} />;
};
