import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from 'react-error-boundary';
import { ZodError } from 'zod';
import axios from 'axios';
// eslint-disable-next-line import/order
import { UNAUTHORIZED } from 'http-status';

import './i18n';

import { equals } from 'ramda';

import { AppConfig, appConfig } from './appConfig';
import { ApiProvider } from './network/ApiProvider';
import { App } from './App';
import { theme } from './theme';
import { AuthProvider } from './AuthProvider';
import { mediaboxEnv } from './mediaboxEnv';
import { IndexGeneralErrorPage } from './GeneralErrorPage';
import { initI18NForApp } from './i18n';

declare global {
  interface Window {
    appConfig: AppConfig;
    recaptchaOptions: {
      enterprise: boolean;
    };
  }
}

const requestErrorHandler = (error: unknown) => {
  const isIgnoredErrorStatus = equals(UNAUTHORIZED);

  if (
    axios.isAxiosError(error) &&
    error.response &&
    isIgnoredErrorStatus(error.response.status)
  ) {
    Sentry.captureException(error, {
      level: 'info',
    });
    return;
  }

  Sentry.captureException(error, {
    level: 'error',
  });
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      ...(process.env.NODE_ENV === 'development' && { networkMode: 'always' }),
      retry: (failureCount, error) => {
        if (error instanceof ZodError) {
          return false;
        }

        return failureCount < 3;
      },
      onError: requestErrorHandler,
    },
    mutations: {
      ...(process.env.NODE_ENV === 'development' && { networkMode: 'always' }),
      onError: requestErrorHandler,
    },
  },
});

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: appConfig.sentryUrl,
    integrations: [new BrowserTracing()],
    environment: mediaboxEnv,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  // debugging info
  Object.assign(window, {
    ___MEDIABOX_INFO_DONT_TOUCH___: {
      mediaboxEnv,
    },
  });
}

(async () => {
  initI18NForApp();
  if (process.env.NODE_ENV === 'development') {
    // a measure to prevent `Mock Service Worker` code from being bundled
    const { worker } = await import(process.env.REACT_APP_MOCK_SERVER_PATH!);
    await worker.start();
  }
  const root = createRoot(document.getElementById('root')!);
  root.render(
    <StrictMode>
      <ChakraProvider theme={theme}>
        <ErrorBoundary FallbackComponent={IndexGeneralErrorPage}>
          <Sentry.ErrorBoundary
            showDialog={false}
            fallback={<IndexGeneralErrorPage />}
          >
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <ApiProvider>
                  <Router>
                    <App />
                  </Router>
                  <ReactQueryDevtools initialIsOpen={false} />
                </ApiProvider>
              </AuthProvider>
            </QueryClientProvider>
          </Sentry.ErrorBoundary>
        </ErrorBoundary>
      </ChakraProvider>
    </StrictMode>,
  );
})();
