import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { useApi } from '../../network/ApiProvider';
import { ApiResponse, AxiosApiError } from '../../network/types';

import { UserRegistrationFormValues } from './types';

type UserRegistrationMutationArgs = Pick<
  UserRegistrationFormValues,
  'email' | 'password'
>;

export const useRegisterUserMutation = (
  opts?: UseMutationOptions<
    ApiResponse,
    AxiosApiError,
    UserRegistrationMutationArgs
  >,
) => {
  const api = useApi();
  return useMutation<ApiResponse, AxiosApiError, UserRegistrationMutationArgs>(
    async (args) => {
      const { data } = await api.post('/api/users', args);

      return data;
    },
    opts,
  );
};
