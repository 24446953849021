import { Box, VStack, Flex, useToast } from '@chakra-ui/react';
import { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DevTool } from '@hookform/devtools';
import { FORBIDDEN } from 'http-status';
import { isEmpty } from 'ramda';

import { Button } from '../../ui-components/Button';
import { useTranslation } from '../../i18n';
import { NetworkErrorAlert } from '../../ui-components/NetworkErrorAlert';

import { ResetPasswordPasswordField } from './ResetPasswordPasswordField';
import { ResetPasswordPasswordConfirmationField } from './ResetPasswordPasswordConfirmationField';
import { useResetPasswordMutation } from './useResetPasswordMutation';

export type ResetPasswordFormProps = { resetToken: string };

export const ResetPasswordForm: FC<ResetPasswordFormProps> = (props) => {
  const { resetToken } = props;

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();

  const resetPasswordMutation = useResetPasswordMutation({
    onSuccess: () => {
      toast({
        title: t(
          'resetPasswordSuccess',
          'Your new password has been set succesfully!',
        ),
        status: 'success',
        isClosable: true,
      });
      navigate('/');
    },
  });

  const formMethods = useForm<{
    password: string;
    passwordConfirmation: string;
  }>(
    process.env.NODE_ENV === 'development'
      ? {
          defaultValues: {
            password: 'asdfasdfA9;',
            passwordConfirmation: 'asdfasdfA9;',
          },
        }
      : {},
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = formMethods;
  i18n.on('languageChanged', () => {
    reset();
  });
  const onSubmit = handleSubmit(({ password }) => {
    resetPasswordMutation.mutate({ newPassword: password, token: resetToken });
  });

  const renderResetPasswordError = () => {
    if (!resetPasswordMutation.isError) {
      return;
    }

    if (
      resetPasswordMutation.error.response?.data?.type ===
      'urn:problem-type:external-new-password-request-not-allowed'
    ) {
      return (
        <NetworkErrorAlert networkError={resetPasswordMutation.error}>
          {t(
            'forgotPasswordReset.externalNewPasswordRequestNotAllowed',
            'External new password request not allowed for INTERNAL user',
          )}
        </NetworkErrorAlert>
      );
    }

    if (resetPasswordMutation.error.response?.status === FORBIDDEN) {
      return (
        <NetworkErrorAlert networkError={resetPasswordMutation.error}>
          {t(
            'forgotPasswordReset.forbidden',
            'Received link is invalid, reset your password again',
          )}
        </NetworkErrorAlert>
      );
    }

    return (
      <NetworkErrorAlert networkError={resetPasswordMutation.error}>
        {t(
          'forgotPasswordReset.generalError',
          'Something went wrong, try again…',
        )}
      </NetworkErrorAlert>
    );
  };

  return (
    <FormProvider {...formMethods}>
      <DevTool control={control} />
      <Box as="form" onSubmit={onSubmit} width="full">
        <VStack alignItems="stretch" spacing={6}>
          <Flex
            as="fieldset"
            direction="column"
            disabled={resetPasswordMutation.isLoading}
          >
            {/* TODO: consider adding hidden login field
            https://www.chromium.org/developers/design-documents/create-amazing-password-forms/ */}
            <ResetPasswordPasswordField />

            <ResetPasswordPasswordConfirmationField />
          </Flex>

          {renderResetPasswordError()}

          <VStack alignItems="stretch">
            <Button
              type="submit"
              width="full"
              isLoading={resetPasswordMutation.isLoading}
              disabled={!isEmpty(errors)}
            >
              {t('forgotPasswordReset.submit', 'DONE')}
            </Button>
          </VStack>
        </VStack>
      </Box>
    </FormProvider>
  );
};
