import {
  AlertIcon,
  AlertProps,
  Box,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';

import { AxiosApiError } from '../network/types';

import { Alert } from './Alert';

export type NetworkErrorAlertProps = {
  networkError: unknown | unknown[];
} & AlertProps;

export const NetworkErrorAlert: FC<NetworkErrorAlertProps> = (props) => {
  const { children, networkError, ...alertProps } = props;

  const renderErrorMeta = () => {
    if (!networkError) {
      return null;
    }

    const errors = Array.isArray(networkError) ? networkError : [networkError];

    if (
      errors.some(
        (error) =>
          error &&
          Boolean((error as AxiosApiError).response?.data?.elkTraceId) &&
          Boolean((error as AxiosApiError).response?.data?.sentryTraceId),
      )
    ) {
      return (
        <HStack spacing={0}>
          <AlertIcon visibility="hidden" />

          <Stack>
            {errors
              .filter(
                (error) =>
                  error &&
                  Boolean(
                    (error as AxiosApiError).response?.data?.elkTraceId,
                  ) &&
                  Boolean(
                    (error as AxiosApiError).response?.data?.sentryTraceId,
                  ),
              )
              .map((error, index) => (
                <Text key={index} fontSize="xs" title="Trace ID">
                  {`${(error as AxiosApiError).response!.data.sentryTraceId}__${
                    (error as AxiosApiError).response!.data.elkTraceId
                  }`}
                </Text>
              ))}
          </Stack>
        </HStack>
      );
    }

    return null;
  };

  return (
    <Alert status="error" {...alertProps}>
      <Stack>
        <HStack spacing={0}>
          <AlertIcon />

          <Box>{children}</Box>
        </HStack>

        {renderErrorMeta()}
      </Stack>
    </Alert>
  );
};
