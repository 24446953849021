import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { useApi } from '../../network/ApiProvider';
import { ApiResponse, AxiosApiError } from '../../network/types';

type UseActivateUserMutationParams = { activationToken: string };

export const useActivateUserMutation = (
  opts?: UseMutationOptions<
    ApiResponse,
    AxiosApiError,
    UseActivateUserMutationParams
  >,
) => {
  const api = useApi();
  return useMutation<ApiResponse, AxiosApiError, UseActivateUserMutationParams>(
    async ({ activationToken }) => {
      const { data } = await api.patch(
        `/api/users/activation/${activationToken}`,
      );

      return data;
    },
    opts,
  );
};
