import { FC, PropsWithChildren } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { NavLink as NL } from 'react-router-dom';

import { useTranslation } from '../i18n';

export type FooterPageProps = PropsWithChildren<{
  navPrefix: string;
}>;

export const Footer: FC<FooterPageProps> = (props) => {
  const { t } = useTranslation();
  return (
    <HStack flex={0} paddingBottom={5} alignSelf={'center'} spacing={8}>
      <NL to={`${props.navPrefix}/imprint`}>
        <Text
          fontWeight="medium"
          fontSize={'lg'}
          color="gray.600"
          _hover={{ color: 'brand.400' }}
        >
          {t('imprintPage.link', 'Imprint')}
        </Text>
      </NL>
      <NL to={`${props.navPrefix}/privacy-policy`}>
        <Text
          fontWeight="medium"
          fontSize={'lg'}
          color="gray.600"
          _hover={{ color: 'brand.400' }}
        >
          {t('privacyPolicyPage.link', 'Privacy Policy')}
        </Text>
      </NL>
    </HStack>
  );
};
