import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { useApi } from '../../network/ApiProvider';
import { ApiResponse, AxiosApiError } from '../../network/types';

type ResetPasswordMutationParams = {
  newPassword: string;
  token: string;
};

export const useResetPasswordMutation = (
  opts?: UseMutationOptions<
    ApiResponse,
    AxiosApiError,
    ResetPasswordMutationParams
  >,
) => {
  const api = useApi();
  return useMutation<ApiResponse, AxiosApiError, ResetPasswordMutationParams>(
    async (args) => {
      const { data } = await api.patch('/api/users/password', args);

      return data;
    },
    opts,
  );
};
