import { FC } from 'react';
import { Center, VStack } from '@chakra-ui/react';

import { Loader, LoaderProps } from './Loader';
import { LanguageFlags } from './LanguageFlags';

export type FullScreenLoaderProps = LoaderProps;

export const FullScreenLoader: FC<FullScreenLoaderProps> = (props) => {
  return (
    <Center
      height="100vh"
      width="100vw"
      as={VStack}
      data-testid="fullscreen-spinner"
    >
      <LanguageFlags />
      <Loader {...props} />
    </Center>
  );
};
