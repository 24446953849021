import { Image, ImageProps } from '@chakra-ui/react';
import { FC } from 'react';

export type FlagIconProps =
  | ImageProps
  | {
      alt: string;
      src: string;
    };

export const FlagIcon: FC<FlagIconProps> = (props) => {
  const { alt, src } = props;

  return <Image width="35px" height="20px" {...props} src={src} alt={alt} />;
};
