import { mediaboxEnv } from './mediaboxEnv';

export const localAppConfig = {
  eppBackendServiceBaseUrl: '/epp-backend',
  fileServiceBaseUrl: '/files-service',
  campaignFilesServingServiceBaseUrl: '/files',
  dictionaryServiceBaseUrl: '/dictionary-service',
  activityServiceBaseUrl: '/activity-service',
  refreshAuthTokenIntervalInMillis: 1781,
  myWorldPspBaseUrl:
    'https://test-partner.myworld.com/projects-2023-mediabox-creditspurchase/it/purchase',
  accountServiceBaseUrl: '/account',
  sentryUrl: '',
  skipRecaptcha: true,
};

export type InitAppConfig = {
  eppBackendServiceBaseUrl: string;
};

type ConfigQueryData = {
  fileServiceBaseUrl: string;
  dictionaryServiceBaseUrl: string;
  activityServiceBaseUrl: string;
  refreshAuthTokenIntervalInMillis: number;
  campaignFilesServingServiceBaseUrl: string;
  myWorldPspBaseUrl: string;
  accountServiceBaseUrl: string;
  sentryUrl: string;
  skipRecaptcha: boolean;
};

const eppBackendURL = () => {
  switch (mediaboxEnv) {
    case 'local': {
      return '/epp-backend';
    }
    case 'production': {
      return 'https://epp-backend.360mediabox.site';
    }
    default: {
      if (!process.env.REACT_APP_EPP_BACKEND_URL) {
        throw new Error('REACT_APP_EPP_BACKEND_URL is not set');
      }
      return process.env.REACT_APP_EPP_BACKEND_URL;
    }
  }
};

const initAppConfig: InitAppConfig = {
  eppBackendServiceBaseUrl: eppBackendURL(),
};

export type AppConfig = InitAppConfig & ConfigQueryData;

if (!['test', 'development'].includes(process.env.NODE_ENV)) {
  const request = new XMLHttpRequest();
  request.open(
    'GET',
    `${initAppConfig.eppBackendServiceBaseUrl}/api/configurations/current`,
    false,
  ); // `false` makes the request synchronous
  request.send(null);

  if (request.status === 200) {
    const responseJSON = JSON.parse(request.response);
    window.appConfig = {
      ...initAppConfig,
      ...responseJSON,
    };
  }
}

export const appConfig = window.appConfig || localAppConfig;
